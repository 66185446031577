import React from "react";
import { Link } from "react-router-dom";
import { Grid, Stack, Paper, Container } from "@mui/material";
import AddUser from "../Assets/images/AddUser.svg";
import UnverifiedAccounts from "../Assets/images/UnverifiedAccounts.svg";
import Admin from "../Assets/images/Admin.svg";
import Customer from "../Assets/images/Customer.svg";
import CustomerWPA from "../Assets/images/CustomerWPA.svg";
import SelfRegistration from "../Assets/images/self-registration.svg";

export default function CustomerHomePage() {
  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 15 }}>
        <Grid container spacing={3} justifyContent="space-evenly">
          <Card label="History" icon={Admin} link="/history" />
          <Card label="Customer" icon={Customer} link="/customer" />
        </Grid>
      </Container>
    </>
  );
}

function Card({ icon, label, link = "" }) {
  return (
    <Grid item xs={4}>
      <Link to={link}>
        <Paper
          style={{
            width: "100%",
            padding: "40px 0",
            color: "white",
            background: "#0B0B45",
          }}
          sx={{
            "&:hover": {
              boxShadow: "0px 0px 30px 0px #0B0B4599",
            },
          }}
        >
          <Stack alignItems="center">
            <img width={100} height={100} src={icon} alt="" />
            <p style={{ margin: "20px 0 0" }}>{label}</p>
          </Stack>
        </Paper>
      </Link>
    </Grid>
  );
}
