import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Stack,
  Typography,
  Paper,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Container,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Box,
  IconButton,
  Autocomplete,
  Select,
  MenuItem,
} from "@mui/material";

import Btn from "../Components/Btn";
import http from "../http";
import GlobalLoading from "../Components/loading";
import GlobalAlert from "../Components/alert";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const defaultFormFields = {
  setting_id: "",
  face_confidence_threshold: "",
  sharpness_threshold: "",
  brightness_threshold: "",
  occlusion_confidence_threshold: "",
  pose_angle_threshold: "",
  eye_open_confidence_threshold: "",
  user_match_confidence_threshold: "",
  green_refresh_time: 3,
  red_refresh_time: 6,
};

export default function Setting() {
  const [formFields, setFormFields] = React.useState(defaultFormFields);

  useEffect(() => {
    handleGetSetting();
  }, []);

  const handleUserInfoTextFieldsChanged = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleGetSetting = async () => {
    try {
      GlobalLoading.show();
      const res = await http.get("/groups/settings");
      setFormFields(res);
      GlobalLoading.hide();
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        setting_id: formFields.setting_id ? formFields.setting_id : undefined,
        face_confidence_threshold: formFields.face_confidence_threshold
          ? formFields.face_confidence_threshold
          : undefined,
        sharpness_threshold: formFields.sharpness_threshold
          ? formFields.sharpness_threshold
          : undefined,
        brightness_threshold: formFields.brightness_threshold
          ? formFields.brightness_threshold
          : undefined,
        occlusion_confidence_threshold:
          formFields.occlusion_confidence_threshold
            ? formFields.occlusion_confidence_threshold
            : undefined,
        pose_angle_threshold: formFields.pose_angle_threshold
          ? formFields.pose_angle_threshold
          : undefined,
        eye_open_confidence_threshold: formFields.eye_open_confidence_threshold
          ? formFields.eye_open_confidence_threshold
          : undefined,
        user_match_confidence_threshold:
          formFields.user_match_confidence_threshold,
        green_refresh_time: formFields.green_refresh_time
          ? formFields.green_refresh_time
          : undefined,
        red_refresh_time: formFields.red_refresh_time
          ? formFields.red_refresh_time
          : undefined,
      };
      GlobalLoading.show();
      const res = await http.put(`/groups/settings/parameters`, payload);
      GlobalLoading.hide();
      console.log("res==>>", res);
      GlobalAlert.success({
        msg: "Update Success !",
      });
    } catch (e) {
      GlobalLoading.hide();
      GlobalAlert.error({
        msg:
          e?.response?.data?.detail?.[0]?.msg ||
          e?.message ||
          "Update Failed !",
      });
      console.log(e);
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12} padding={10}>
          <Stack>
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={5}>
                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="face_confidence_threshold"
                      variant="outlined"
                      name="face_confidence_threshold"
                      value={formFields["face_confidence_threshold"]}
                      onChange={handleUserInfoTextFieldsChanged}
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="sharpness_threshold"
                      variant="outlined"
                      name="sharpness_threshold"
                      value={formFields["sharpness_threshold"]}
                      onChange={handleUserInfoTextFieldsChanged}
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="brightness_threshold"
                      variant="outlined"
                      name="brightness_threshold"
                      value={formFields["brightness_threshold"]}
                      onChange={handleUserInfoTextFieldsChanged}
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="occlusion_confidence_threshold"
                      variant="outlined"
                      name="occlusion_confidence_threshold"
                      value={formFields["occlusion_confidence_threshold"]}
                      onChange={handleUserInfoTextFieldsChanged}
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="pose_angle_threshold"
                      variant="outlined"
                      name="pose_angle_threshold"
                      value={formFields["pose_angle_threshold"]}
                      onChange={handleUserInfoTextFieldsChanged}
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="eye_open_confidence_threshold"
                      variant="outlined"
                      name="eye_open_confidence_threshold"
                      value={formFields["eye_open_confidence_threshold"]}
                      onChange={handleUserInfoTextFieldsChanged}
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="user_match_confidence_threshold"
                      variant="outlined"
                      name="user_match_confidence_threshold"
                      value={formFields["user_match_confidence_threshold"]}
                      onChange={handleUserInfoTextFieldsChanged}
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="green_refresh_time"
                      variant="outlined"
                      name="green_refresh_time"
                      value={formFields["green_refresh_time"]}
                      onChange={handleUserInfoTextFieldsChanged}
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid item lg={4} xs={12}>
                    <TextField
                      label="red_refresh_time"
                      variant="outlined"
                      name="red_refresh_time"
                      value={formFields["red_refresh_time"]}
                      onChange={handleUserInfoTextFieldsChanged}
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid item lg={12} xs={12}>
                    <Btn type="submit" startIcon={<BorderColorIcon />}>
                      Submit
                    </Btn>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
