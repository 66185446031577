import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Container,
  TextField,
  Typography,
  DialogTitle,
  Autocomplete,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Btn from "./Components/Btn";
import MyDataGrid from "./Components/MyDataGrid";
import http from "./http";
import GlobalLoading from "./Components/loading";
import GlobalAlert from "./Components/alert";

export default function UnverifiedAccounts() {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [item, setItem] = useState({});

  const handleGetDataSource = async () => {
    try {
      GlobalLoading.show();
      const res = await http.get(`/customers/?admin_approved=False`);
      GlobalLoading.hide();
      setEmployees(res.items);
    } catch (e) {
      console.log(e);
      GlobalLoading.hide();
      if (e?.response?.data?.detail) {
        GlobalAlert.error({
          msg: e?.response?.data?.detail,
        });
      } else {
        GlobalAlert.error({
          msg: e.message || "Error !",
        });
      }
    }
  };

  useEffect(() => {
    handleGetDataSource();
  }, []);

  const handleDelete = async () => {
    try {
      const res = await http.delete(`/customers/${item.customer_id}`);
      console.log("handleDelete res", res);
      if (res.status_code === 200) {
        GlobalAlert.success({
          msg: "Delete Success !",
          finish: () => {
            handleGetDataSource();
          },
        });
      } else {
        GlobalAlert.error({
          msg: res.detail || "Delete Failed !",
        });
      }
    } catch (e) {
      GlobalAlert.error({
        msg: "Delete Failed !",
      });
    }
  };

  const employeesColumns = [
    {
      field: "first_name",
      headerName: "Name",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.first_name} {params?.row?.last_name}
          </span>
        );
      },
    },
    {
      field: "member_id",
      headerName: "Member Id",
      width: 200,
      editable: false,
    },
    {
      field: "picture",
      headerName: "Picture",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <img
            style={{
              maxWidth: 90,
              maxHeight: 90,
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src={params?.row?.image_s3_path}
            alt="Preview"
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div>
          <Btn
            variant="contained"
            onClick={() => {
              setSelectedEmployee(params["row"]);
              setDialogOpened(true);
            }}
          >
            Approve
          </Btn>
          <Btn
            sx={{ ml: 1 }}
            variant="outlined"
            color="error"
            onClick={() => {
              setItem(params["row"]);
              setDeleteDialogOpened(true);
            }}
          >
            Delete
          </Btn>
        </div>
      ),
    },
  ];

  const handleEmployeeSubmit = async (data) => {
    try {
      const payload = {
        group_id: selectedEmployee.group_id,
        first_name: selectedEmployee.first_name,
        last_name: selectedEmployee.last_name,
        email: selectedEmployee.email,
        image_s3_path: selectedEmployee.image_s3_path,
        customer_id: selectedEmployee.customer_id,
        member_id: selectedEmployee.member_id,
        category: data.selectedCategory,
        ...data,
        admin_approved: true,
      };
      GlobalLoading.show();
      const res = await http.put(
        `/customers/${selectedEmployee.customer_id}`,
        payload
      );
      GlobalLoading.hide();
      console.log("res==>>", res);
      setDialogOpened(false);
      GlobalAlert.success({
        msg: "Approved Success !",
        finish: () => {
          handleGetDataSource();
        },
      });
    } catch (e) {
      GlobalLoading.hide();
      GlobalAlert.error({
        msg:
          e?.response?.data?.detail?.[0]?.msg ||
          e?.message ||
          "Update Failed !",
      });
      console.log(e);
    }
  };

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>
        <Box sx={{ height: 400 }}>
          <Typography variant="h5" component="h5">
            Unverified Accounts
          </Typography>
          <MyDataGrid
            rows={employees}
            columns={employeesColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            getRowId={(row) => row?.customer_id}
            rowHeight={100}
          />
        </Box>

        <Dialog
          open={dialogOpened}
          onClose={() => setDialogOpened(false)}
          maxWidth="md"
        >
          <ConfirmDialog
            data={selectedEmployee}
            handleClose={() => setDialogOpened(false)}
            handleSubmit={handleEmployeeSubmit}
          />
        </Dialog>

        <Dialog
          open={deleteDialogOpened}
          onClose={() => setDeleteDialogOpened(false)}
          maxWidth="md"
        >
          <DeleteDialog
            data={selectedEmployee}
            handleClose={() => setDeleteDialogOpened(false)}
            handleSubmit={handleDelete}
          />
        </Dialog>
      </Container>
    </>
  );
}

function ConfirmDialog({ data, handleClose, handleSubmit }) {
  const [category, setCategory] = useState("Member");
  const [optional_a, setoptional_a] = useState();
  const [optional_b, setoptional_b] = useState();
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        handleSubmit({
          category,
          optional_a,
          optional_b,
        });
      }}
    >
      <DialogTitle>{"Approve"}</DialogTitle>
      <DialogContent sx={{ width: 400 }}>
        <Autocomplete
          sx={{ mt: 2 }}
          value={category}
          options={[
            "Member",
            "Guest",
            "Not allowed",
            "Test",
            "Other1",
            "Other2",
          ]}
          onChange={(e, val) => setCategory(val)}
          renderInput={(params) => (
            <TextField {...params} label="Category" required />
          )}
          autoFocus
        />

        <TextField
          style={{ marginTop: 24 }}
          label="Optional A"
          value={optional_a}
          name="optional_a"
          onChange={(e) => {
            setoptional_a(e.target.value);
          }}
          fullWidth
        />

        <TextField
          style={{ marginTop: 24 }}
          label="Optional B"
          value={optional_b}
          name="optional_b"
          onChange={(e) => {
            setoptional_b(e.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button color="primary" type="submit" disabled={isLoading}>
          Submit
        </Button>
      </DialogActions>
    </form>
  );
}

function DeleteDialog({ data, handleClose, handleSubmit }) {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        handleSubmit();
      }}
    >
      <DialogTitle>{"Confirm Delete ?"}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          type="submit"
          disabled={isLoading}
        >
          Delete
        </Button>
      </DialogActions>
    </form>
  );
}
