import React from "react";
import { Link } from "react-router-dom";
import { Grid, Stack, Paper, Container } from "@mui/material";
import SelfRegistration from "./Assets/images/self-registration.svg";
import Login from "./Assets/images/login.svg";

export default function PublicHome() {
  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Grid container spacing={3} justifyContent="space-evenly">
        {/*<Card*/}
        {/*  label="Guest Enroll"*/}
        {/*  icon={SelfRegistration}*/}
        {/*  link="/self-registration"*/}
        {/*/>*/}
        <Card label="Login" icon={Login} link="/login" />
      </Grid>
    </Container>
  );
}

function Card({ icon, label, link = "" }) {
  return (
    <Grid item sm={4}>
      <Link to={link}>
        <Paper
          style={{
            width: "368px",
            height: "6rem",
            padding: "20px  0",
            color: "white",
            background: "#0B0B45",
          }}
          sx={{
            "&:hover": {
              boxShadow: "0px 0px 30px 0px #0B0B4599",
            },
          }}
        >
          <Stack alignItems="center">
            <img width="50" height="50" src={icon} alt="" />
            <p style={{ margin: "20px 0 0", height: "40px" }}>{label}</p>
          </Stack>
        </Paper>
      </Link>
    </Grid>
  );
}
