import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import MyDataGrid from "../Components/MyDataGrid";
import { styled } from "@mui/material/styles";
import { ERROR_CLR } from "../data";
import moment from "moment";
import GlobalLoading from "../Components/loading";
import http from "../http";

let timer = null;
const TIMER_COUNT = 5000;

export default function Recognized() {
  const [dataSource, setDataSource] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const pageRef = useRef(0);

  const columns = [
    {
      field: "customer_id",
      headerName: "ID",
      width: 120,
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.customer_id}</span>;
      },
    },
    {
      field: "category",
      headerName: "Status",
      width: 100,
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.category}</span>;
      },
    },
    {
      field: "optional_a",
      headerName: "Optional A",
      width: 100,
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.optional_a}</span>;
      },
    },
    {
      field: "optional_b",
      headerName: "Optional B",
      width: 100,
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.optional_b}</span>;
      },
    },
    {
      field: "image_s3_path",
      width: 100,
      headerName: "Image",
      renderCell: ({ row }) => (
        <img
          src={`${row["image_s3_path"]}`}
          alt="employee"
          style={{ width: 70, height: 70, borderRadius: "50%" }}
        />
      ),
    },
    {
      field: "first_name",
      width: 100,
      headerName: "Name",
      renderCell: ({ row }) => (
        <span style={{ fontSize: 12 }}>
          {row.first_name} {row.last_name}
        </span>
      ),
    },
    {
      field: "confidence",
      width: 100,
      headerName: "Confidence",
      renderCell: ({ row }) => (
        <span style={{ fontSize: 12 }}>{row.match_confidence}</span>
      ),
    },
    {
      field: "checkin_time",
      headerName: "Time",
      width: 160,
      renderCell: ({ row }) => (
        <span style={{ fontSize: 12 }}>
          {moment(row.checkin_time).format("h:mm a, Do MMM YYYY")}
        </span>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleGetApprovedList({
          page: pageRef.current,
        });
      } catch (e) {}
    };
    fetchData();
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleGetApprovedList = async (opt = {}) => {
    const { hideLoading, page = 0 } = opt;
    clearTimeout(timer);
    try {
      if (!hideLoading) {
        GlobalLoading.show();
      }
      // 支持page翻页
      const res = await http.get(
        `/checkin/?is_recognized=True&page=${page + 1}`
      );
      setDataSource(res.items);
      setRowCount(res.total);
      setPage(page);
      pageRef.current = page;
      if (!hideLoading) {
        GlobalLoading.hide();
      }
      timer = setTimeout(() => {
        const pathname = window.location.pathname;
        if (pathname === "/history") {
          handleGetApprovedList({
            page: pageRef.current,
            hideLoading: true,
          });
        }
      }, TIMER_COUNT);
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  return (
    <div style={{ height: "inherit" }}>
      <Typography sx={{ mb: 1 }}>
        <strong style={{ color: "#0B0B45" }}>Recognized</strong>
      </Typography>
      <StyledDataGrid
        rows={dataSource}
        columns={columns}
        density="comfortable"
        rowHeight={60}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        rowsPerPageOptions={[20]}
        getRowId={(row) => {
          return row.checkin_id + "" + Math.random() * 1000000000;
        }}
        getRowClassName={({ row }) =>
          row["category"] === "Not Allowed" ||
          row["category"] === "Not allowed" ||
          row["paramount_status"] === "NOT_ALLOWED" ||
          (row["paramount_status"] !== "G" &&
            row["paramount_status"] !== "NOT_APPLICABLE_FOR_NON_GYM" &&
            row["paramount_status"] !== "NOT_APPLICABLE_FOR_GUEST")
            ? "paramount-error"
            : ""
        }
        disableSelectionOnClick
        pageSize={20}
        page={page}
        onPageChange={(newPage) => {
          handleGetApprovedList({
            page: newPage,
          });
        }}
        pagination
        paginationMode="server"
        rowCount={rowCount}
      />
    </div>
  );
}

const StyledDataGrid = styled(MyDataGrid)(() => ({
  "& .paramount-error": {
    backgroundColor: ERROR_CLR,
    "&:hover": {
      backgroundColor: ERROR_CLR,
    },
  },
}));
