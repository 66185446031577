import React from "react";
import { Grid, Stack, Container } from "@mui/material";
import Recognized from "./recognized";
import Unrecognized from "./unrecognized";

export default function Admin() {
  return (
    <Container sx={{ marginTop: "20px", height: "100vh" }}>
      <Grid container spacing={2} sx={{ mb: 5 }}>
        <Grid item xs={12} md={7} style={{ height: "670px" }}>
          <Recognized />
        </Grid>

        <Grid item xs={12} md={5}>
          <Stack spacing={2}>
            <div style={{ height: "670px" }}>
              <Unrecognized />
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
