import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  DialogContent,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";

import Btn from "../Components/Btn";
import http from "../http";
import GlobalLoading from "../Components/loading";
import GlobalAlert from "../Components/alert";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MyDataGrid from "../Components/MyDataGrid";
import SearchIcon from "@mui/icons-material/Search";
import PlusIcon from "@mui/icons-material/AddCircleOutline";
import PeopleIcon from "@mui/icons-material/People";

export default function Staff() {
  const [dataSource, setDataSource] = useState([]);
  const [deleteUserInfo, setDeleteUserInfo] = useState(null);
  const [username, setUsername] = useState("");
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [visible, setVisible] = useState(false);
  const [roleVisible, setRoleVisible] = useState(false);
  const [staffInfo, setStaffInfo] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [group, setGroup] = useState("staff");

  const handleEditByRow = async (data) => {
    try {
      setVisible(true);
      setIsEdit(true);
      setStaffInfo(data);
      setGroup(data?.groups?.[0] || "staff");
    } catch (e) {}
  };

  const handleEditRoleByRow = async (data) => {
    try {
      setRoleVisible(true);
      setStaffInfo(data);
      setGroup(data?.groups?.[0] || "staff");
    } catch (e) {}
  };

  const handleSearchData = async () => {
    try {
      if (username) {
        GlobalLoading.show();
        const res = await http.get(`/users/${username}`);
        GlobalLoading.hide();
        setDataSource([
          {
            ...res,
          },
        ]);
      } else {
        GlobalAlert.error({
          msg: "Please input username",
        });
      }
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  const handleDelete = async (e) => {
    try {
      GlobalLoading.show();
      const res = await http.delete(`/users/${deleteUserInfo.username}`, {
        data: deleteUserInfo,
      });
      GlobalLoading.hide();
      console.log("handleDelete res", res);
      if (res.status_code === 200) {
        GlobalAlert.success({
          msg: "Delete Success !",
          finish: () => {},
        });
        const $data = dataSource.filter(
          (d) => d.username !== deleteUserInfo.username
        );
        setDataSource($data);
      } else {
        GlobalAlert.error({
          msg: res.detail || "Delete Failed !",
        });
      }
    } catch (e) {
      GlobalLoading.hide();
      GlobalAlert.error({
        msg: "Delete Failed !",
      });
    }
  };

  const handleModalSubmit = (event) => {
    event.preventDefault();
    if (isEdit) {
      handleUpdateStaff();
    } else {
      handleCreateStaffAsync();
    }
  };

  const handleCreateClub = async () => {
    setStaffInfo({});
    setIsEdit(false);
    setVisible(true);
  };

  const handleCreateStaffAsync = async (event) => {
    try {
      const payload = {
        ...staffInfo,
        groups: [group],
      };
      GlobalLoading.show();
      const res = await http.post(`/users/`, payload);
      console.log("res==>>", res);
      GlobalLoading.hide();
      if (!res.status_code) {
        GlobalAlert.success({
          msg: "Create Success !",
        });
      } else {
        GlobalAlert.success({
          msg: "Create Failed !",
        });
      }
      setVisible(false);
    } catch (e) {
      GlobalAlert.error({
        msg: e.response.data.detail || "Create Failed !",
      });
      GlobalLoading.hide();
    }
  };

  const handleUpdateStaff = async (event) => {
    try {
      const payload = {
        ...staffInfo,
        groups: [group],
      };
      GlobalLoading.show();
      const res = await http.put(`/users/${staffInfo.username}`, payload);
      console.log("res==>>", res);
      GlobalLoading.hide();
      if (!res.status_code) {
        GlobalAlert.success({
          msg: "Update Success !",
        });
        setDataSource([
          {
            ...res,
          },
        ]);
      } else {
        GlobalAlert.success({
          msg: "Update Failed !",
        });
      }
      setVisible(false);
    } catch (e) {
      GlobalAlert.error({
        msg: e.response.data.detail || "Update Failed !",
      });
      GlobalLoading.hide();
    }
  };

  const handleUpdateRoleSubmit = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        username: staffInfo.username,
        groups: [group],
        club_id: staffInfo.club_id,
      };
      GlobalLoading.show();
      const res = await http.put(`/users/${staffInfo.username}/group`, payload);
      console.log("res==>>", res);
      GlobalLoading.hide();
      if (!res.status_code) {
        GlobalAlert.success({
          msg: "Update Success !",
        });
        const $data = dataSource.filter(
          (d) => d.username === staffInfo.username
        );
        $data[0].groups = [group];

        setDataSource($data);
      } else {
        GlobalAlert.success({
          msg: "Update Failed !",
        });
      }
      setRoleVisible(false);
    } catch (e) {
      GlobalAlert.error({
        msg: e.response.data.detail || "Update Failed !",
      });
      GlobalLoading.hide();
    }
  };

  const columns = [
    {
      field: "username",
      headerName: "Username",
      width: 200,
    },
    {
      field: "groups",
      headerName: "Role",
      width: 200,
      renderCell: ({ row }) => <div>{row.groups[0]}</div>,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 200,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "club_id",
      headerName: "Club Id",
      width: 200,
    },
    {
      field: "action",
      headerName: "Action",
      fixed: "right",
      width: 200,
      renderCell: ({ row }) => (
        <div>
          <IconButton
            onClick={() => {
              handleEditByRow(row);
            }}
          >
            <BorderColorIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleEditRoleByRow(row);
            }}
          >
            <PeopleIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteUserInfo(row);
              setDeleteDialogOpened(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  console.log("isEdit==>>", isEdit);

  return (
    <Grid container spacing={5} padding={5}>
      <Grid
        item
        paddingLeft={5}
        xs={6}
        lg={6}
        style={{
          height: 80,
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          label="Username"
          variant="outlined"
          name="username"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          style={{ width: "50%", marginRight: 30 }}
        />
        <Btn onClick={handleSearchData} startIcon={<SearchIcon />}>
          Search
        </Btn>
      </Grid>
      <Grid
        item
        xs={6}
        lg={6}
        style={{
          height: 80,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Btn onClick={handleCreateClub} startIcon={<PlusIcon />}>
          Create Staff
        </Btn>
      </Grid>
      <Grid item lg={12} xs={12} style={{ height: 500 }}>
        <MyDataGrid
          rows={dataSource}
          columns={columns}
          density="comfortable"
          pageSize={20}
          rowHeight={60}
          rowsPerPageOptions={[20]}
          getRowId={(row) => {
            return row.username + "" + Math.random() * 1000000000;
          }}
          disableSelectionOnClick
        />
      </Grid>
      <Dialog
        open={deleteDialogOpened}
        onClose={() => setDeleteDialogOpened(false)}
        maxWidth="md"
      >
        <DeleteDialog
          handleClose={() => setDeleteDialogOpened(false)}
          handleSubmit={() => {
            setDeleteDialogOpened(false);
            handleDelete();
          }}
        />
      </Dialog>

      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        PaperProps={{
          component: "form",
          onSubmit: handleModalSubmit,
        }}
      >
        {isEdit && <DialogTitle>Update Staff</DialogTitle>}
        {!isEdit && <DialogTitle>Create Staff</DialogTitle>}
        <DialogContent>
          <TextField
            label="Club Id"
            variant="outlined"
            name="club_id"
            style={{ marginTop: 32 }}
            value={staffInfo["club_id"]}
            onChange={(e) => {
              setStaffInfo({
                ...staffInfo,
                club_id: e.target.value,
              });
            }}
            fullWidth
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            style={{ marginTop: 32 }}
            value={staffInfo["email"]}
            onChange={(e) => {
              setStaffInfo({
                ...staffInfo,
                email: e.target.value,
              });
            }}
            fullWidth
            required
          />
          <Autocomplete
            style={{ marginTop: 32 }}
            value={group}
            options={["staff", "admin"]}
            onChange={(e, val) => setGroup(val)}
            renderInput={(params) => (
              <TextField {...params} label="Role" required />
            )}
            autoFocus
          />
          <TextField
            label="first_name"
            variant="outlined"
            name="first_name"
            style={{ marginTop: 32 }}
            value={staffInfo["first_name"]}
            onChange={(e) => {
              setStaffInfo({
                ...staffInfo,
                first_name: e.target.value,
              });
            }}
            fullWidth
            required
          />
          <TextField
            label="last_name"
            variant="outlined"
            name="last_name"
            style={{ marginTop: 32 }}
            value={staffInfo["last_name"]}
            onChange={(e) => {
              setStaffInfo({
                ...staffInfo,
                last_name: e.target.value,
              });
            }}
            fullWidth
            required
          />
          <TextField
            label="username"
            variant="outlined"
            name="username"
            style={{ marginTop: 32 }}
            value={staffInfo["username"]}
            onChange={(e) => {
              setStaffInfo({
                ...staffInfo,
                username: e.target.value,
              });
            }}
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisible(false)}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={roleVisible}
        onClose={() => setRoleVisible(false)}
        PaperProps={{
          component: "form",
          onSubmit: handleUpdateRoleSubmit,
        }}
      >
        <DialogTitle>Update Role</DialogTitle>
        <DialogContent>
          <Autocomplete
            style={{ marginTop: 32, width: 300 }}
            value={group}
            options={["staff", "admin"]}
            onChange={(e, val) => setGroup(val)}
            renderInput={(params) => (
              <TextField {...params} label="Role" required />
            )}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRoleVisible(false)}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

function DeleteDialog({ handleClose, handleSubmit }) {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        handleSubmit();
      }}
    >
      <DialogTitle>{"Confirm Delete ?"}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          type="submit"
          disabled={isLoading}
        >
          Delete
        </Button>
      </DialogActions>
    </form>
  );
}
