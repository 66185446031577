import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Container, Alert } from "@mui/material";
import Btn from "../../Components/Btn";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { UserContext } from "../../contexts/user.context";

const theme = createTheme();

const defaultFormFields = {
  email: "",
  password: "",
  phoneNumber: "",
  confirmPassword: "",
};

export default function CustomerSignup() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const [errorMessages, setErrorMessages] = React.useState("");

  const { signup } = React.useContext(UserContext)

  // destructured object
  const { email, phoneNumber, password, confirmPassword } = formFields;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
    setErrorMessages("");
  };

  const resetFormField = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessages("Password and Confirm Password must be same");
      return;
    }

    try {
      setIsLoading(true);
      await signup(email, '+'+phoneNumber, password, 'customer')
      resetFormField();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.code === "auth/email-already-in-use") {
        setErrorMessages("Email already in use");
      } else if (error.code === "auth/invalid-email") {
        setErrorMessages("Please enter valid email");
      } else if (error.code === "auth/weak-password") {
        setErrorMessages("Password should be at least 6 characters");
      } else {
        setErrorMessages("Something went wrong. Please try again later");
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {errorMessages && (
          <Alert severity="error" sx={{ mt: 3 }}>
            {errorMessages}
          </Alert>
        )}
        <Box
          sx={{
            marginTop: 3,
            marginBottom: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              value={email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Phone Number"
              type="number"
              name="phoneNumber"
              onChange={handleChange}
              value={phoneNumber}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={handleChange}
              value={password}
            />

            <TextField
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              onChange={handleChange}
              value={confirmPassword}
              fullWidth
              required
              margin="normal"
            />
            <Btn
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              Sign Up
            </Btn>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
