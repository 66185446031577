import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  DialogContent,
  Autocomplete,
} from "@mui/material";

import Btn from "../Components/Btn";
import http from "../http";
import GlobalLoading from "../Components/loading";
import GlobalAlert from "../Components/alert";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MyDataGrid from "../Components/MyDataGrid";
import SearchIcon from "@mui/icons-material/Search";
import PlusIcon from "@mui/icons-material/AddCircleOutline";
import moment from "moment";
import { getUserRole, getClubId } from "../authUtils.js";

export default function Club() {
  const [dataSource, setDataSource] = useState([]);
  const [deleteUserInfo, setDeleteUserInfo] = useState(null);
  const [group_id, setGroupId] = useState("");
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [visible, setVisible] = useState(false);
  const [clubInfo, setClubInfo] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const userRole = getUserRole();
  const localClubId = getClubId();

  const handleEditByRow = async (data) => {
    try {
      setVisible(true);
      setIsEdit(true);
      setClubInfo(data);
    } catch (e) {}
  };

  const handleGetData = async (page) => {
    try {
      GlobalLoading.show();
      const res = await http.get(`/groups/search`);
      GlobalLoading.hide();
      if (res.items?.length > 0) {
        setDataSource(res.items);
      }
      console.log("all group res===>>", res);
    } catch (e) {
      console.log("all group e==>>", e);
      GlobalLoading.hide();
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const handleSearchData = async () => {
    try {
      if (group_id) {
        GlobalLoading.show();
        const res = await http.get(`/groups/search?group_id=${group_id}`);
        GlobalLoading.hide();
        if (res.items?.length > 0) {
          setDataSource(res.items);
        }
      } else {
        handleGetData();
      }
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  const handleDelete = async (e) => {
    try {
      GlobalLoading.show();
      const res = await http.delete(`/groups/${deleteUserInfo.group_id}`);
      GlobalLoading.hide();
      console.log("handleDelete res", res);
      if (res.status_code === 200) {
        GlobalAlert.success({
          msg: "Delete Success !",
          finish: () => {},
        });
      } else {
        GlobalAlert.error({
          msg: res.detail || "Delete Failed !",
        });
      }
    } catch (e) {
      GlobalLoading.hide();
      GlobalAlert.error({
        msg: "Delete Failed !",
      });
    }
  };

  const handleModalSubmit = (event) => {
    event.preventDefault();
    if (isEdit) {
      handleUpdateClub();
    } else {
      handleCreateClubAsync();
    }
  };

  const handleCreateClub = async () => {
    setClubInfo({});
    setIsEdit(false);
    setVisible(true);
  };

  const handleCreateClubAsync = async (event) => {
    try {
      const data = {
        group_id: clubInfo.group_id,
        group_name: clubInfo.group_name,
        is_gym: JSON.parse(clubInfo.is_gym),
        email_categories: clubInfo.email_categories,
        customer_email_categories: clubInfo.customer_email_categories,
      };
      GlobalLoading.show();
      const res = await http.post(`/groups/`, data);
      console.log("res==>>", res);
      GlobalLoading.hide();
      if (!res.status_code) {
        GlobalAlert.success({
          msg: "Create Success !",
        });
      } else {
        GlobalAlert.success({
          msg: "Create Failed !",
        });
      }
      setVisible(false);
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  const handleUpdateClub = async (event) => {
    console.log("club", clubInfo);
    try {
      const data = {
        group_id: clubInfo.group_id,
        group_name: clubInfo.group_name,
        is_gym: JSON.parse(clubInfo.is_gym),
        email_categories: clubInfo.email_categories,
        customer_email_categories: clubInfo.customer_email_categories,
      };
      GlobalLoading.show();
      const res = await http.put(`/groups/${clubInfo.group_id}`, data);
      console.log("res==>>", res);
      GlobalLoading.hide();
      if (!res.status_code) {
        GlobalAlert.success({
          msg: "Update Success !",
        });
        setDataSource([
          {
            ...res,
          },
        ]);
      } else {
        GlobalAlert.success({
          msg: "Update Failed !",
        });
      }
      setVisible(false);
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  const columns = [
    {
      field: "group_id",
      headerName: "Club Id",
      width: 200,
    },
    {
      field: "group_name",
      headerName: "Club Name",
      width: 200,
    },
    {
      field: "is_gym",
      width: 130,
      headerName: "Is GYM",
    },
    {
      field: "email_categories",
      width: 270,
      headerName: "Email Categories",
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              fontSize: 12,
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {row.email_categories?.map((d) => {
              return (
                <div
                  style={{
                    display: "inline-block",
                    padding: "3px 8px",
                    fontSize: "10px",
                    background: "#bebcbc",
                    margin: 5,
                    borderRadius: 4,
                  }}
                  key={d}
                >
                  {d}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "customer_email_categories",
      width: 270,
      headerName: "Customer Email Categories",
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              fontSize: 12,
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {row.customer_email_categories?.map((d) => {
              return (
                <div
                  style={{
                    display: "inline-block",
                    padding: "3px 8px",
                    fontSize: "10px",
                    background: "#bebcbc",
                    margin: 5,
                    borderRadius: 4,
                  }}
                  key={d}
                >
                  {d}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "create_time",
      headerName: "Create Time",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <span style={{ fontSize: 12 }}>
            {moment(row.create_time).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: ({ row }) => (
        <div>
          <IconButton
            onClick={() => {
              handleEditByRow(row);
            }}
          >
            <BorderColorIcon />
          </IconButton>
          {userRole === "superadmin" && (
            <IconButton
              onClick={() => {
                setDeleteUserInfo(row);
                setDeleteDialogOpened(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  return (
    <Grid container spacing={5} padding={5}>
      {userRole === "superadmin" && (
        <Grid
          item
          paddingLeft={5}
          xs={6}
          lg={6}
          style={{
            height: 80,
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            label="Club Id"
            variant="outlined"
            name="group_id"
            value={group_id}
            onChange={(e) => {
              setGroupId(e.target.value);
            }}
            style={{ width: "50%", marginRight: 30 }}
          />
          <Btn onClick={handleSearchData} startIcon={<SearchIcon />}>
            Search
          </Btn>
        </Grid>
      )}
      {userRole === "superadmin" && (
        <Grid
          item
          xs={6}
          lg={6}
          style={{
            height: 80,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Btn onClick={handleCreateClub} startIcon={<PlusIcon />}>
            Create Club
          </Btn>
        </Grid>
      )}
      <Grid item lg={12} xs={12} style={{ height: 500 }}>
        <MyDataGrid
          rows={dataSource}
          columns={columns}
          density="comfortable"
          pageSize={20}
          rowHeight={60}
          rowsPerPageOptions={[20]}
          getRowId={(row) => {
            return row.group_id + "" + Math.random() * 1000000000;
          }}
          disableSelectionOnClick
        />
      </Grid>
      <Dialog
        open={deleteDialogOpened}
        onClose={() => setDeleteDialogOpened(false)}
        maxWidth="md"
      >
        <DeleteDialog
          handleClose={() => setDeleteDialogOpened(false)}
          handleSubmit={handleDelete}
        />
      </Dialog>

      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        PaperProps={{
          component: "form",
          onSubmit: handleModalSubmit,
        }}
      >
        {isEdit && <DialogTitle>Update Club</DialogTitle>}
        {!isEdit && <DialogTitle>Create Club</DialogTitle>}
        <DialogContent>
          <TextField
            required
            margin="dense"
            value={clubInfo.group_id}
            name="group_id"
            label="Club Id"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setClubInfo({
                ...clubInfo,
                group_id: e.target.value,
              });
            }}
            disabled={userRole !== "superadmin"}
          />
          <TextField
            required
            margin="dense"
            name="group_name"
            value={clubInfo.group_name}
            label="Club Name"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setClubInfo({
                ...clubInfo,
                group_name: e.target.value,
              });
            }}
            disabled={userRole !== "superadmin"}
          />

          <Autocomplete
            style={{ marginTop: 32 }}
            value={JSON.stringify(clubInfo.is_gym)}
            options={["true", "false"]}
            onChange={(e, val) => {
              setClubInfo({
                ...clubInfo,
                is_gym: val,
              });
            }}
            renderInput={(params) => (
              <TextField
                disabled={userRole !== "superadmin"}
                label="Is_gym"
                {...params}
                required
                fullWidth
              />
            )}
            autoFocus
            disabled={userRole !== "superadmin"}
          />

          <Autocomplete
            multiple
            style={{ marginTop: 32 }}
            value={clubInfo.email_categories || []}
            options={[
              "Member",
              "Guest",
              "Not allowed",
              "Test",
              "Other1",
              "Other2",
            ]}
            onChange={(e, val) => {
              console.log("val==>>", val);
              setClubInfo({
                ...clubInfo,
                email_categories: val,
              });
            }}
            renderInput={(params) => {
              return (
                <TextField label="Email Categories" {...params} fullWidth />
              );
            }}
            autoFocus
            disabled={
              localClubId !== clubInfo.group_id && userRole !== "superadmin"
            }
          />

          <Autocomplete
            multiple
            style={{ marginTop: 32 }}
            value={clubInfo.customer_email_categories || []}
            options={[
              "Member",
              "Guest",
              "Not allowed",
              "Test",
              "Other1",
              "Other2",
            ]}
            onChange={(e, val) => {
              setClubInfo({
                ...clubInfo,
                customer_email_categories: val,
              });
            }}
            renderInput={(params) => {
              return (
                <TextField
                  label="Customer Email Categories"
                  {...params}
                  fullWidth
                />
              );
            }}
            autoFocus
            disabled={
              localClubId !== clubInfo.group_id && userRole !== "superadmin"
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisible(false)}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

function DeleteDialog({ handleClose, handleSubmit }) {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        handleSubmit();
      }}
    >
      <DialogTitle>{"Confirm Delete ?"}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          type="submit"
          disabled={isLoading}
        >
          Delete
        </Button>
      </DialogActions>
    </form>
  );
}
