// guest-vision-secret110
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithPhoneNumber,
} from "firebase/auth";
import axios from 'axios'



const firebaseConfig = {
  apiKey: "AIzaSyCrG7OPYbo0C6r1htUFpqgI2QFYSuyQVPs",
  authDomain: "vacon-3eba6.firebaseapp.com",
  projectId: "vacon-3eba6",
  storageBucket: "vacon-3eba6.appspot.com",
  messagingSenderId: "946297717213",
  appId: "1:946297717213:web:8cd1795a7f33909b7c7634",
};

// eslint-disable-next-line no-unused-vars
const firebaseApp = initializeApp(firebaseConfig);


export const auth = getAuth(firebaseApp);
export const authForCreateNewAccounts = getAuth(initializeApp(firebaseConfig, 'secondary'))

// auth.tenantId = 'guest-vision-uys2h'
// const recaptchaVerifier = new RecaptchaVerifier('2fa-captcha', {
//   size: 'invisible',
//   callback: function (response) {
//     console.log('captcha solved!');
//   },
// }, auth);

export const createClubUsers = async (adminEmail, adminPassword, customerEmail, customerPassword) => {
  if (!adminEmail || !adminPassword || !customerEmail || !customerPassword) return;

  try {
    const adminRes = await createUserWithEmailAndPassword(authForCreateNewAccounts, adminEmail, adminPassword);
    const customerRes = await createUserWithEmailAndPassword(authForCreateNewAccounts, customerEmail, customerPassword);

    const adminId = adminRes.user.uid
    const customerId = customerRes.user.uid
    return { adminId, customerId }
  }
  catch (err) {
    console.log(err)
    throw err
  }
}

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  const signupRes = await createUserWithEmailAndPassword(auth, email, password);
  // try {
  //   const phoneNumber = '+923130187182'
  //   const user = auth.currentUser
  //   const session = await multiFactor(user).getSession()
  //   const phoneOpts = {
  //     phoneNumber,
  //     session,
  //   };

  //   const phoneauthprovider = new PhoneAuthProvider()
  //   const verificationId = await phoneauthprovider.verifyPhoneNumber(phoneOpts, recaptchaVerifier)
  //   console.log(verificationId)
  // }
  // catch (err) {
  //   console.error(err)
  // }
  return signupRes
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithPhoneAndPassword = async (phoneNumber, password) => {
  if (!phoneNumber || !password) return

  return await signInWithPhoneNumber(auth, phoneNumber, password)
}

export const sendForgetPasswordEmail = async (email) => {
  if (!email) return

  await sendPasswordResetEmail(auth, email)
}

export const signOutAuthUser = async () => {
  await signOut(auth);
};

export const onAuthStateChangedListener = (callback) => {
  onAuthStateChanged(auth, callback);
};


export const sendEmailVerificationToAuthUser = async () => {
  await sendEmailVerification(auth.currentUser);
}

export const asyncLoginFn = async () => {
  try {
    const data = new FormData()
    data.append('username', 'celeste-dev')
    data.append('password', 'Ascending123%')
    const res = await axios.post('http://127.0.0.1:8000/guestvision/auth/token', data, {
      headers: {
        'Content-Type': "application/x-www-form-urlencoded"
      }
    })
    return res
  } catch (e) {
    return {}
  }
}
