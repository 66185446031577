import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Grid,
  Stack,
  Typography,
  Paper,
  Checkbox,
  Container,
  TextField,
  Dialog,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import Btn from "./Components/Btn";
import axios from "axios";
import AvatarIcon from "./Assets/images/avatar.png";
import StraightFace from "./Assets/images/straight-face.png";
import AngleFace from "./Assets/images/angle-face.png";
import AngleFace2 from "./Assets/images/angle-face-2.png";
import faceMaskImg from "./Assets/images/face-mask.png";
import http from "./http";
import GlobalLoading from "./Components/loading";
import GlobalAlert from "./Components/alert";
import * as faceapi from "@vladmandic/face-api";
import { CANVAS_WIDTH, CANVAS_HEIGHT } from "./customer/utils";
import { MODELS_URL } from "./data";

const EXAMPLE_IMGS = [StraightFace, AngleFace, AngleFace2, AngleFace2];

const defaultFormFields = {
  first_name: "",
  last_name: "",
  member_id: "",
  email: "",
  group_id: "",
  image_s3_path: "",
  category: "",
};

const defaultQuestionnaireFields = {
  comments: "",
};

export default function SelfRegistration() {
  const location = useLocation();
  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const [questionnaireForm, setQuestionnaire] = React.useState(
    defaultQuestionnaireFields
  );
  const [previewImages, setPreviewImages] = React.useState([]);
  const [isAcknowledged, setIsAcknowledged] = React.useState(false);
  const [isCapturingPicture, setIsCapturingPicture] = React.useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [s3Info, setS3Info] = useState(null);
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState(false);
  const [category, setCategory] = useState("Member");
  const [age_range, setAgeRange] = useState("");
  const [gender, setGender] = useState("");
  const [showQuestionnaire] = useState(false); // location.search?.includes("questionnaire=1")
  const [modelsLoaded, setModelsLoaded] = React.useState(false);

  const { first_name, last_name, member_id, group_id, email, image_s3_path } =
    formFields;

  const { comments } = questionnaireForm;

  // 加载第三方插件（人脸识别）
  useEffect(() => {
    async function loadModels() {
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODELS_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODELS_URL),
      ])
        .then(() => setModelsLoaded(true))
        .catch((err) => {
          alert("Cannot load models. Please try again");
        });
    }
    loadModels();
  }, []);

  useEffect(() => {
    const { first_name, last_name, group_id } = formFields;
    if (first_name && last_name && group_id) {
      setNextDisabled(false);
    }
  }, [JSON.stringify(formFields)]);

  const handleReset = () => {
    setFormFields(defaultFormFields);
    setQuestionnaire(defaultQuestionnaireFields);
    setS3Info(null);
    setPreviewImages([]);
    setIsAcknowledged(false);
    setNextDisabled(true);
    setNextButtonLoading(false);
    setCategory("Member");
  };

  // 获取上传凭证，url，key
  const handleGetS3Info = async (group_id) => {
    try {
      GlobalLoading.show();
      const res = await http.get(`/customers/presignURL?group_id=${group_id}`);
      GlobalLoading.hide();
      console.log("res==>>", res);
      setS3Info(res);
    } catch (e) {
      console.log("e==>>", e);
      GlobalLoading.hide();
      GlobalAlert.error({
        msg: e?.response?.data?.detail || e.message,
      });
    }
  };

  const handleGoStep2 = async () => {
    if (group_id) {
      await handleGetS3Info(group_id);
      setTimeout(() => {
        try {
          window.scrollTo(0, document.body.scrollHeight);
        } catch (e) {}
      }, 300);
    } else {
      GlobalAlert.error({
        msg: "Please input your Club Id",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        first_name: first_name,
        last_name: last_name,
        member_id: member_id ? member_id : undefined,
        group_id: group_id,
        email: email ? email : undefined,
        image_s3_path: image_s3_path,
        // category: category,
      };
      const questionnairePayload = {
        ...questionnaireForm,
        age_range,
        gender,
      };
      // if need showQuestionnaire
      const submitPayload = {
        customer: payload,
        questionnaire: showQuestionnaire ? questionnairePayload : undefined,
      };
      GlobalLoading.show();
      const res = await http.post("/customers/self-enrollment", payload);
      GlobalLoading.hide();
      console.log("submit res==>>", res);
      if (res.status_code) {
        GlobalAlert.error({
          msg: res.detail || "Add Failed !",
        });
      } else {
        GlobalAlert.success({
          msg: "Add Success !",
          finish: () => {
            handleReset();
          },
        });
      }
    } catch (err) {
      console.log("submit err", err);
      GlobalLoading.hide();
      GlobalAlert.show({
        type: "error",
        msg:
          err?.response?.data?.detail?.[0]?.msg ||
          err?.response?.data?.detail ||
          err.message,
      });
    }
  };

  const handleTextFieldsChanged = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleQuestionnaireFormTextFieldsChanged = (e) => {
    const { name, value } = e.target;
    setQuestionnaire({ ...questionnaireForm, [name]: value });
  };

  const handleImgsUpload = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const uploadedFiles = [...e.target.files].slice(0, 3);
    handleUploadImageToS3(uploadedFiles[0]);
  };

  const handleAddAvatar = (file) => {
    const reader = new FileReader();

    // 文件加载完成时的回调函数
    reader.onload = function (event) {
      // 将文件读取结果作为图片的 src 属性值
      setPreviewImages([{ ...file, src: event.target.result }]);
    };
    // 读取文件内容
    reader.readAsDataURL(file);
  };

  const handleUploadImageToS3 = async (file) => {
    try {
      const payload = new FormData();
      payload.append("Content-Type", s3Info.fields["Content-Type"]);
      payload.append("key", s3Info.fields["key"]);
      payload.append("AWSAccessKeyId", s3Info.fields["AWSAccessKeyId"]);
      payload.append("policy", s3Info.fields["policy"]);
      payload.append("signature", s3Info.fields["signature"]);
      payload.append("file", file);
      handleAddAvatar(file);
      if (s3Info && s3Info.url) {
        GlobalLoading.show();
        setUploadBtnDisabled(true);
        const res = await axios.post(s3Info.url, payload, {
          headers: {
            "Content-Type": "multipart/form-data;",
          },
        });
        setUploadBtnDisabled(false);
        GlobalLoading.hide();
        GlobalAlert.success({
          msg: "Upload Success !",
          duration: 6000,
        });
        setFormFields({
          ...formFields,
          image_s3_path: s3Info.fields["key"],
        });
      }
    } catch (e) {
      setUploadBtnDisabled(false);
      GlobalLoading.hide();
      GlobalAlert.show({
        type: "error",
        msg: "Upload Error !",
      });
    }
  };

  const pictureCaptured = (img) => {
    console.log("img==>>", img);
    setIsCapturingPicture(false);
    handleUploadImageToS3(img);
  };

  return (
    <Container maxWidth="xl">
      <h2 style={{ textAlign: "center" }}>Guest Vision Enrollment</h2>
      <Grid container>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Stack>
              <div style={{ width: "100%" }}>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{ mb: 3, mt: 5, mx: "auto" }}
                >
                  Step 1: Add Information
                </Typography>
                <Grid container spacing={5}>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="First name"
                      value={first_name}
                      onChange={handleTextFieldsChanged}
                      name="first_name"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Last name"
                      value={last_name}
                      onChange={handleTextFieldsChanged}
                      name="last_name"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Club Id"
                      value={group_id}
                      onChange={handleTextFieldsChanged}
                      name="group_id"
                      fullWidth
                      required
                      helperText="Case Sensitive"
                    />
                  </Grid>
                  {/*<Grid item lg={3} xs={12}>
                    <Autocomplete
                      value={category}
                      options={[
                        "Member",
                        "Guest",
                        "Not allowed",
                        "Test",
                        "Other1",
                        "Other2",
                      ]}
                      onChange={(e, val) => setCategory(val)}
                      renderInput={(params) => (
                        <TextField label="Category" {...params} fullWidth />
                      )}
                      autoFocus
                    />
                  </Grid>*/}
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Email"
                      value={email}
                      onChange={handleTextFieldsChanged}
                      name="email"
                      fullWidth
                      helperText="Optional for check-in receipts"
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <TextField
                      label="Member Id"
                      value={member_id}
                      onChange={handleTextFieldsChanged}
                      name="member_id"
                      fullWidth
                      helperText="Optional if known"
                    />
                  </Grid>
                </Grid>

                <div style={{ margin: "32px auto" }}>
                  <Btn
                    sx={{ width: 200 }}
                    variant="contained"
                    disabled={nextDisabled}
                    loading={nextButtonLoading}
                    onClick={handleGoStep2}
                  >
                    Next
                  </Btn>
                </div>
              </div>

              {s3Info && (
                <div style={{ width: "100%" }}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ mb: 3, mt: 2, mx: "auto" }}
                    style={{ marginTop: 50 }}
                  >
                    Step 2: Take Selfie
                  </Typography>
                  <Paper
                    elevation={0}
                    style={{
                      background: "#FCFCFC",
                      border: "dashed 2px #727272",
                      padding: 20,
                    }}
                  >
                    <Grid
                      container
                      spacing={4}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item lg={4} xs={12}>
                        <Stack alignItems="center">
                          <img
                            src={
                              previewImages.length > 0
                                ? previewImages[0]?.src
                                : AvatarIcon
                            }
                            alt=""
                            style={{
                              width: 200,
                              height: 200,
                              background: "#ddd",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                            <Btn
                              variant="contained"
                              component="label"
                              disabled={uploadBtnDisabled}
                            >
                              Upload
                              <input
                                type="file"
                                onChange={handleImgsUpload}
                                accept="image/*"
                                hidden
                              />
                            </Btn>
                            <Btn
                              variant="contained"
                              onClick={() => setIsCapturingPicture(true)}
                              disabled={!modelsLoaded}
                            >
                              Capture
                            </Btn>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <Stack alignItems="center">
                          <img
                            style={{
                              width: 200,
                              height: 200,
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            src={EXAMPLE_IMGS[0] /*[currPictureIndex]*/}
                            alt=""
                          />
                          <p
                            align="center"
                            style={{
                              color: "#6C6C6C",
                              maxWidth: 300,
                              fontSize: 15,
                            }}
                          >
                            Example
                          </p>
                          {/* <Btn variant="contained">Save</Btn> */}
                        </Stack>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <p
                          style={{
                            textAlign: "justify",
                            fontSize: 24,
                            fontWeight: "bold",
                          }}
                        >
                          Tips: Make sure there is good lighting, your face
                          should cover 90% of the circle, don't wear blocking
                          objects i.e glasses, hat etc.
                        </p>
                      </Grid>
                    </Grid>
                  </Paper>

                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ mb: 3, mt: 5, mx: "auto" }}
                  >
                    Step 3: Check box & Submit
                  </Typography>

                  {showQuestionnaire ? (
                    <Grid container spacing={5}>
                      {/*<Grid item lg={3} xs={12}>
                        <Autocomplete
                          value={age_range}
                          options={[
                            "18-25",
                            "26-30",
                            "31-35",
                            "36-40",
                            "41-45",
                            "46-50",
                            "50+",
                          ]}
                          onChange={(e, val) => setAgeRange(val)}
                          renderInput={(params) => (
                            <TextField
                              label="Please choose your age range"
                              {...params}
                              fullWidth
                            />
                          )}
                          autoFocus
                        />
                      </Grid>*/}
                      {/*<Grid item lg={3} xs={12}>
                        <Autocomplete
                          value={gender}
                          options={["Male", "Female"]}
                          onChange={(e, val) => setGender(val)}
                          renderInput={(params) => (
                            <TextField
                              label="What is your gender"
                              {...params}
                              fullWidth
                            />
                          )}
                          autoFocus
                        />
                      </Grid>*/}
                      {/*<Grid item lg={6} xs={12}>
                        <TextField
                          label="Any comments to improve our experience"
                          value={comments}
                          onChange={handleQuestionnaireFormTextFieldsChanged}
                          name="comments"
                          fullWidth
                        />
                      </Grid>*/}
                    </Grid>
                  ) : (
                    <div />
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAcknowledged}
                        onChange={(e) => setIsAcknowledged(e.target.checked)}
                      />
                    }
                    label="By submitting this form, you are acknowledging and consenting for your image to be used for facial recognition and check-in purposes. Your personal information will not be shared with any outside organizations."
                    sx={{
                      mt: 5,
                      span: {
                        fontSize: 15,
                        color: "#6C6C6C",
                      },
                    }}
                  />

                  <div style={{ margin: "24px 0" }}>
                    Contact info@guest.vision for removal requests.
                  </div>

                  <div style={{ margin: "32px auto" }}>
                    <Btn
                      sx={{ width: 200 }}
                      variant="contained"
                      type="submit"
                      disabled={!isAcknowledged}
                    >
                      Submit
                    </Btn>
                  </div>
                </div>
              )}
            </Stack>
          </form>
        </Grid>
      </Grid>

      <Dialog
        sx={{ bgcolor: "transparent" }}
        open={isCapturingPicture}
        onClose={() => setIsCapturingPicture(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <TakePicture pictureCaptured={pictureCaptured} />
      </Dialog>
    </Container>
  );
}

const CHECK_IN_STATUS = {
  empty: 0,
  success: 1,
  warning: 2,
  error: 3,
  unrecognized: 4,
  closer: 5,
  farther: 6,
  holdStill: 7,
  center: 8,
};

function TakePicture({ pictureCaptured }) {
  const [checkInStatus, setCheckInStatus] = React.useState(
    CHECK_IN_STATUS.empty
  );
  const [capturedImg, setCapturedImg] = React.useState(null);
  const [finish, setFinish] = React.useState(false);
  const stream = React.useRef();
  const isFetching = React.useRef();
  const videoRef = React.useRef();
  const canvasRef = React.useRef();
  const WIDTH = CANVAS_WIDTH;
  const HEIGHT = CANVAS_HEIGHT;
  let detectFunIntervalTimer;

  React.useEffect(() => {
    (async () => {
      const cameras = await navigator.mediaDevices.enumerateDevices();
      if (cameras.length === 0) return alert("No cameras detected");

      const st = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { deviceId: cameras[0].deviceId },
      });
      videoRef.current.srcObject = st;
      videoRef.current.play();
      stream.current = st;
    })();

    return () => {
      stream.current?.getTracks().forEach((t) => t.stop());
    };
  }, []);

  /***
   * 画线，人脸方框
   * @param box
   * @param canvas
   * @param drawOptions
   */
  const drawFaceLineToCanvas = (box, canvas, drawOptions) => {
    // 使用Canvas API绘制边界框
    canvas.getContext("2d").strokeStyle = drawOptions.boxColor;
    canvas.getContext("2d").lineWidth = drawOptions.lineWidth;
    canvas.getContext("2d").strokeRect(box.x, box.y, box.width, box.height);

    // 添加文本
    canvas.getContext("2d").fillStyle = drawOptions.labelTextColor;
    canvas.getContext("2d").font = `${drawOptions.fontSize}px Arial`;
    const text = drawOptions.label;
    const textWidth = canvas.getContext("2d").measureText(text).width;
    const textX = box.x + (box.width - textWidth) / 2; // 文本水平居中
    const textY = box.y - drawOptions.fontSize; // 文本垂直居上
    canvas.getContext("2d").fillText(text, textX, textY);
  };

  function stopCamera() {
    clearTimeout(detectFunIntervalTimer);
    setCheckInStatus(CHECK_IN_STATUS.empty);
    videoRef.current = undefined;
    canvasRef.current = undefined;
    isFetching.current = false;
  }

  /***
   * 获取人脸识别图片
   * @returns {Promise<void>}
   */
  async function detectFrame() {
    if (finish) {
      // 清除旧的人脸方框
      canvasRef.current?.getContext("2d").clearRect(0, 0, 300, 400);
      return;
    }

    if (isFetching.current) {
      console.log("正在上传...");
      // 清除旧的人脸方框
      canvasRef.current?.getContext("2d").clearRect(0, 0, 300, 400);
      return;
    }

    if (!(canvasRef && canvasRef.current)) {
      return;
    }

    const detection = await faceapi.detectSingleFace(
      videoRef.current,
      new faceapi.TinyFaceDetectorOptions()
    );

    if (!detection) {
      return;
    }

    const videoHeight = videoRef.current?.height || CANVAS_HEIGHT;
    const videoWidth = videoRef.current?.width || CANVAS_WIDTH;

    const resizedDetection = faceapi.resizeResults(detection, {
      width: videoWidth,
      height: videoHeight,
    });

    // 清除旧的人脸方框
    canvasRef.current.getContext("2d").clearRect(0, 0, videoWidth, videoHeight);

    // 红色人脸框
    const defaultDrawOptions = {
      labelTextColor: "red", // 标签文本颜色
      lineWidth: 4, // 边界框线宽
      label: ``,
      boxColor: "red", // 边界框颜色
      fontSize: 18, // 标签字体大小
    };

    drawFaceLineToCanvas(
      resizedDetection["_box"],
      canvasRef.current,
      defaultDrawOptions
    );

    const boxLeft = resizedDetection["_box"].left;
    const boxTop = resizedDetection["_box"].top;

    console.log("boxTop==>>", boxTop);

    // 检测人脸宽高必须大于110px（太远了） 必须小于155（太近了）
    if (resizedDetection["_box"]["_height"] < 110) {
      console.log("== too small =="); // Please move closer
      setCheckInStatus(CHECK_IN_STATUS.closer);
      return false;
    }

    if (resizedDetection["_box"]["_height"] > 155) {
      console.log("== too far =="); // Too close, please move farther
      setCheckInStatus(CHECK_IN_STATUS.farther);
      return false;
    }

    // 检测人脸是否处于画布中心附近
    if (boxLeft < 90 || boxLeft > 170 || boxTop < 70 || boxTop > 150) {
      console.log("== not center ==");
      setCheckInStatus(CHECK_IN_STATUS.center);
      return false;
    }

    // 判断人脸识别系数是否大于0.6
    if (detection) {
      const confidenceScore = detection.score;
      if (confidenceScore > 0.6) {
        setCheckInStatus(CHECK_IN_STATUS.holdStill);

        // 绿色人脸框
        const drawOptions = {
          labelTextColor: "#03ff04", // 标签文本颜色
          lineWidth: 4, // 边界框线宽
          label: `${confidenceScore.toFixed(2)}`,
          boxColor: "#03ff04", // 边界框颜色
          fontSize: 18, // 标签字体大小
        };

        drawFaceLineToCanvas(
          resizedDetection["_box"],
          canvasRef.current,
          drawOptions
        );

        // 大于0.7的系数，然后进行上传图片识别
        const box = detection._box;
        // 从方框中提取脸部图片
        const faces = await faceapi.extractFaces(videoRef.current, [
          new faceapi.Rect(
            box._x - 100,
            box._y - 150,
            box._width + 200,
            box._height + 200
          ), // 矫正，原方法是从方框中提取图片，图片太小，导致识别不准确，现在加大图片
        ]);
        const currDetection = faces[0];
        if (currDetection) {
          currDetection.toBlob(function (file) {
            setCapturedImg(file);
            setFinish(true);
          });
        }
      } else {
        // 红色人脸框
        const drawOptions = {
          labelTextColor: "red", // 标签文本颜色
          lineWidth: 4, // 边界框线宽
          label: `${confidenceScore.toFixed(2)}`,
          boxColor: "red", // 边界框颜色
          fontSize: 18, // 标签字体大小
        };

        drawFaceLineToCanvas(
          resizedDetection["_box"],
          canvasRef.current,
          drawOptions
        );
      }
    } else {
      console.log("confidenceScore. no");
    }
  }

  const handleVideoPlay = useCallback(() => {
    try {
      setTimeout(() => {
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
          videoRef.current
        );

        faceapi.matchDimensions(canvasRef.current, {
          width: videoRef.current?.width || CANVAS_WIDTH,
          height: videoRef.current?.height || CANVAS_HEIGHT,
        });
        function detectFunInterval() {
          clearTimeout(detectFunIntervalTimer);
          detectFunIntervalTimer = setTimeout(() => {
            detectFrame();
            detectFunInterval();
          }, 300);
        }
        detectFunInterval();
      }, 0);
    } catch (e) {
      console.log("handleVideoPlay e==>>", e);
    }
  }, []);

  const takePhoto = () => {
    const context = canvasRef.current.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, WIDTH, HEIGHT);
    canvasRef.current.toBlob((file) => {
      setCapturedImg(file);
    });
    // stopCamera();
  };

  const handleSubmitPicture = () => {
    const img = new File([capturedImg], Math.random().toString(36) + ".jpg");
    pictureCaptured(img);
  };

  return (
    //  <div >
    <Stack
      spacing={1}
      sx={{ m: 2 }}
      style={{
        position: "fixed",
        top: "7%",
        left: "50%",
        transform: "translateX(-50%)",
        background: "white",
        padding: "8px",
      }}
    >
      <div style={{ position: "relative" }}>
        <video
          style={{ display: capturedImg ? "none" : "block" }}
          ref={videoRef}
          width={WIDTH}
          height={HEIGHT}
          onLoadedMetadata={handleVideoPlay}
        />
        {!capturedImg && (
          <img
            src={faceMaskImg}
            alt=""
            style={{
              position: "absolute",
              width: "400px",
              height: "300px",
              left: 0,
              top: 0,
            }}
          />
        )}
      </div>

      {!capturedImg && (
        <div>
          <div>
            {checkInStatus === CHECK_IN_STATUS.center && (
              <div
                style={{
                  height: 50,
                  lineHeight: "50px",
                  display: "block",
                  fontSize: "22px",
                  fontWeight: "bold",
                  padding: 16,
                  borderRadius: 5,
                  background: "#f12d5a",
                  color: "#fff",
                }}
              >
                Please keep your face centered.
              </div>
            )}
            {checkInStatus === CHECK_IN_STATUS.closer && (
              <div
                style={{
                  height: 50,
                  lineHeight: "50px",
                  display: "block",
                  fontSize: "22px",
                  fontWeight: "bold",
                  padding: 16,
                  borderRadius: 5,
                  background: "#faca72",
                }}
              >
                Please move closer.
              </div>
            )}
            {checkInStatus === CHECK_IN_STATUS.holdStill && (
              <div
                style={{
                  height: 50,
                  lineHeight: "50px",
                  display: "block",
                  fontSize: "22px",
                  fontWeight: "bold",
                  padding: 16,
                  borderRadius: 5,
                  background: "#62f563",
                }}
              >
                Please hold still.
              </div>
            )}
            {checkInStatus === CHECK_IN_STATUS.farther && (
              <div
                style={{
                  height: 50,
                  lineHeight: "50px",
                  display: "block",
                  fontSize: "22px",
                  fontWeight: "bold",
                  padding: 16,
                  borderRadius: 5,
                  background: "#faca72",
                }}
              >
                Too close, please move farther.
              </div>
            )}
          </div>
          {/*<Btn variant="contained" fullWidth onClick={takePhoto}>
            Take picture
          </Btn>*/}
        </div>
      )}

      {capturedImg && (
        <>
          <img src={URL.createObjectURL(capturedImg)} width={WIDTH} />
          <Stack direction="row" spacing={1}>
            <Btn variant="contained" onClick={handleSubmitPicture} fullWidth>
              Keep picture
            </Btn>
            <Btn
              variant="contained"
              onClick={() => {
                setFinish(false);
                setCapturedImg(null);
              }}
              fullWidth
            >
              Take another
            </Btn>
          </Stack>
        </>
      )}
      {!capturedImg && (
        <canvas
          width={WIDTH}
          height={HEIGHT}
          ref={canvasRef}
          style={{
            width: CANVAS_WIDTH,
            height: CANVAS_HEIGHT,
            position: "absolute",
            left: 0,
            borderRadius: "15px",
          }}
        />
      )}
    </Stack>
    //  </div>
  );
}
// spacing={1} sx={{ m: 2 }}
