import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Admin from "./admin/Admin";
import CustomerHome from "./customer/CustomerHome";
import AdminHome from "./admin/Home";
import UpdateUser from "./admin/UpdateUser";
import SelfRegistration from "./SelfRegistration";
import Staff from "./admin/Staff";
import Setting from "./admin/Setting";
import Club from "./admin/Club";
import MainAppBar from "./appbar";
import CustomerLoginSignup from "./auth/CustomerLoginSignup";
import UnverifiedAccounts from "./UnverifiedAccounts";
import PublicHome from "./PublicHome";
import NotFound from "./NotFound";
import { SettingsProvider } from "./contexts/SettingsContext"; // Make sure to provide the correct path to your SettingsContext file
import "./App.css";
import CustomerHomePage from "./customer/CustomerHomePage";
import {
  getUserInfo,
  getUserRole,
  setToken,
  asyncGetUserInfo,
} from "./authUtils.js";
import AdminView from "./admin/AdminView";

function App() {
  const [username, setUsr] = useState(getUserInfo());
  const [userRole, setUsrRole] = useState(getUserRole());

  useEffect(() => {
    async function fetchData() {
      await asyncGetUserInfo();
      setUsr(getUserInfo());
      setUsrRole(getUserRole());
    }
    fetchData();
  }, [window.location.pathname]);

  function getIdTokenFromUrl(url) {
    // 分割 URL
    const urlParts = url.split("#");
    // 如果 URL 不包含哈希部分或者哈希部分不包含 id_token 参数，则返回空字符串
    if (urlParts.length < 2 || !urlParts[1].includes("id_token=")) {
      return "";
    }
    // 分割哈希部分，并找到包含 id_token 参数的部分
    const hashParts = urlParts[1].split("&");
    for (let i = 0; i < hashParts.length; i++) {
      const keyValue = hashParts[i].split("=");
      if (keyValue[0] === "access_token") {
        // 返回 id_token 的值
        return decodeURIComponent(keyValue[1]);
      }
    }
    // 如果没有找到 id_token 参数，则返回空字符串
    return "";
  }

  useEffect(() => {
    // 判断URL是否有token，有token，缓存
    const token = getIdTokenFromUrl(window.location.href);
    if (token) {
      setToken(token);
      // 缓存token之后，跳转到根路由，避免token在url上暴露
      window.location.replace("/");
    }
  }, []);

  // admin  customer          （superadmin这个角色不要了） // 有变动，应该是需要superadmin权限
  return (
    <div className="App">
      <SettingsProvider>
        <MainAppBar />
        <Routes>
          <Route path="/login" element={<CustomerLoginSignup />} />
          <Route path="/customer" element={<CustomerHome />} />
          <Route path="/history" element={<Admin />} />
          <Route path="/unverified-members" element={<UnverifiedAccounts />} />
          <Route
            path="/add-user"
            element={username ? <SelfRegistration /> : <Navigate to="login" />}
          />
          <Route
            path="/staff"
            element={
              userRole === "admin" || userRole === "superadmin" ? (
                <Staff />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/admin"
            element={
              userRole === "admin" || userRole === "superadmin" ? (
                <AdminView />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/club"
            element={
              userRole === "admin" || userRole === "superadmin" ? (
                <Club />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/update-user"
            element={
              userRole === "admin" ||
              userRole === "staff" ||
              userRole === "superadmin" ? (
                <UpdateUser />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/self-registration"
            element={username ? <SelfRegistration /> : <Navigate to="login" />}
          />
          <Route path="/self-enrollment" element={<SelfRegistration />} />

          <Route
            path="/setting"
            element={
              userRole === "superadmin" ? <Setting /> : <Navigate to="/" />
            }
          />

          <Route
            path="/"
            element={
              !username ? (
                <PublicHome />
              ) : userRole === "customer" ? (
                <CustomerHomePage />
              ) : userRole === "admin" ||
                userRole === "superadmin" ||
                userRole === "staff" ? (
                <AdminHome />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </SettingsProvider>
    </div>
  );
}

export default App;
