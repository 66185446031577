import React from "react";
import { createRoot } from "react-dom/client";
import SyncIcon from "@mui/icons-material/Sync";

const GlobalLoading = {
  show: function (style = {}) {
    const container = document.createElement("div");
    container.classList.add("loading-container"); // 添加类名
    document.body.appendChild(container);
    createRoot(container).render(<Loading style={style} />);
  },
  hide: function () {
    const loadingContainers = document.querySelectorAll(".loading-container");
    loadingContainers.forEach((container) => {
      container.parentNode.removeChild(container);
    });
  },
};

const Loading = (props) => {
  const { style = {} } = props;
  return (
    <div
      style={{
        position: "fixed",
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0,0,0, 0.3)",
        ...style,
      }}
    >
      <div>
        <SyncIcon fontSize="large" className={"loading-button"} />
      </div>
    </div>
  );
};

export default GlobalLoading;
