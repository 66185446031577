import { Button } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";

export default function Btn({
  onClick,
  sx = {},
  disabled = false,
  variant = "",
  children,
  loading,
  ...props
}) {
  if (variant === "outlined") {
    var background = "white";
    var hoverBackground = "#a2a2a262";
    var color = "#0B0B45";
    var border =
      disabled || loading ? "1px solid #DFDFE1" : "1px solid #0B0B45";
  } else {
    var background = disabled || loading ? "#DFDFE1" : "#0B0B45";
    var hoverBackground = "#0b0b45c2";
    var color = "white";
    var border = "";
  }

  return (
    <Button
      onClick={onClick}
      disabled={disabled || loading}
      sx={{
        borderRadius: "25px",
        background: background,
        border: border,
        color: color,
        py: 1,
        px: 2,
        "&:hover": {
          background: hoverBackground,
        },
        ...sx,
      }}
      {...props}
    >
      {loading && <SyncIcon className={"loading-button"} />}
      {children}
    </Button>
  );
}
