const DETECTION_STATUS = {
  NO_DETECTION: 0, // empty
  REQ_SENT_FOR_RECOGNITION: 1,
  RECOGNIZED: 2,
  ERROR_SHARPNESS: 3,
  ERROR_BRIGHTNESS: 4,
  ERROR_FACE_NOT_FOUND: 5,
};

const DISTANCE_THRESHOLD = 0.6;
const HOR_DIVIATION_THRESHOLD = 60;
const VER_DIVIATION_THRESHOLD = 70;
const CANVAS_WIDTH = 400;
const CANVAS_HEIGHT = 300;

const DETECTION_DELAY = 250;
const RESET_CACHE_FRAME_DELAY = 10;
const RESET_CACHE_FRAME_DELAY_AFTER_N_FRAMES =
  (1000 / DETECTION_DELAY) * RESET_CACHE_FRAME_DELAY;

// for api urls detect-person
const poseThresholdValue = 10;
const faceSharpnessThresholdValue = 30;
const faceBrightnessThresholdValue = 30;

export {
  DETECTION_STATUS,
  DISTANCE_THRESHOLD,
  HOR_DIVIATION_THRESHOLD,
  VER_DIVIATION_THRESHOLD,
  CANVAS_WIDTH,
  CANVAS_HEIGHT,
  DETECTION_DELAY,
  RESET_CACHE_FRAME_DELAY,
  RESET_CACHE_FRAME_DELAY_AFTER_N_FRAMES,
  poseThresholdValue,
  faceSharpnessThresholdValue,
  faceBrightnessThresholdValue,
};
