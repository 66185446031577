import { Link } from "react-router-dom"
import {
  Container,
  Paper,
  Button,
  Typography,
} from '@mui/material'
import Btn from "./Components/Btn"


export default function NotFound() {
  return (
    <Container>
      <Paper
        sx={{
          py: 12,
          mt: 15,
          color: '#C51B0AE3',
          textAlign: 'center'
        }}
      >
        <Typography variant="h3" component="h3">404</Typography>
        <p style={{ fontSize: 20 }}>Page not Found</p>
        <Link to="/" replace>
          <Btn
            sx={{
              mt: 6,
              width: 300,
              height: 50,
            }}
          >
            Go to Home
          </Btn>
        </Link>
      </Paper>
    </Container>
  )
}
