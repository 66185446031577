import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Button, Container, Alert } from "@mui/material";
import Btn from "../../Components/Btn";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { UserContext } from "../../contexts/user.context";


const theme = createTheme();

const defaultFormFields = {
  email: "",
  password: "",
};

export default function CustomerLogin() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const [isForgetPassword, setIsForgetPassword] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const [errorMessages, setErrorMessages] = React.useState("");

  const { login, forgetPassword } = React.useContext(UserContext)

  // destructured object
  const { email, password } = formFields;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
    setErrorMessages("")
  };

  const resetFormField = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();


    if (isForgetPassword) {
      submitForgetPassword()
      return
    }
    submitLogin()
  };

  const submitLogin = async () => {
    try {
      setIsLoading(true);
      await login(email, password, 'customer')
      resetFormField();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error === "invalid email/password") {
        setErrorMessages("Invalid email/password");
      } else {
        setErrorMessages("invalid credentials ");
      }
      console.log(error)
    }
  }

  const submitForgetPassword = async () => {
    try {
      setIsLoading(true);
      setSuccessMessage('')
      setErrorMessages('')
      await forgetPassword(email)
      resetFormField();
      setIsLoading(false);
      setSuccessMessage('Please check your inbox')
    }
    catch (err) {
      setIsLoading(false);
      if (err === 'auth/user-not-found' || err === 'auth/invalid-email')
        setErrorMessages('Email does not exists')
      else {
        setErrorMessages("invalid credentials")
        console.error(err)
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {errorMessages && (
          <Alert severity="error" sx={{ mt: 3 }}>
            {errorMessages}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ mt: 3 }}>
            {successMessage}
          </Alert>
        )}
        <Box
          sx={{
            marginTop: 3,
            marginBottom: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              value={email}
            />
            {!isForgetPassword && <>
              <TextField
                margin="normal"
                required={!isForgetPassword}
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                onChange={handleChange}
                value={password}
              />

              <Button onClick={() => setIsForgetPassword(true)} sx={{ ml: 'auto', p: 0 }}>
                Forget password
              </Button>
            </>}

            <Btn
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              {isForgetPassword ? 'Reset Password' : 'Login'}
            </Btn>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
