import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Tab, Tabs, Typography } from "@mui/material";

import CustomerLogin from "./customer/CustomerLogin";
import CustomerSignup from "./customer/CustomerSignup";

import { LOGIN_URL } from "../authUtils";

export default function CustomerLoginSignup() {
  console.log("LOGIN_URL==>>", LOGIN_URL);

  window.location.href = LOGIN_URL;

  return <div></div>;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container sx={{ mt: 10 }}>
        <Typography component="h1" variant="h5" align="center" sx={{ mb: 3 }}>
          {/* Customer */}
        </Typography>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            border: "1px solid #e0e0e0",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="admin-auth"
              variant="fullWidth"
            >
              <Tab label="Login" />
              <Tab label="Sign Up" />
            </Tabs>
          </Box>
          {value === 0 ? <CustomerLogin /> : <CustomerSignup />}
        </Container>
      </Container>
    </>
  );
}
