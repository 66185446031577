import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  DETECTION_DELAY,
  RESET_CACHE_FRAME_DELAY,
  RESET_CACHE_FRAME_DELAY_AFTER_N_FRAMES,
  poseThresholdValue,
  faceSharpnessThresholdValue,
  faceBrightnessThresholdValue,
} from '../customer/utils'

const SETTINGS_STORAGE_KEY = 'detectionSettings';// for local storage 

const SettingsContext = createContext();

export function useSettings() {
  return useContext(SettingsContext);
}


export function SettingsProvider({ children }) {
  const [detectionSettings, setDetectionSettings] = useState(() => {
    const storedSettings = localStorage.getItem(SETTINGS_STORAGE_KEY);
    if (storedSettings) {
      return JSON.parse(storedSettings);
    } else {
      return {
        DETECTION_DELAY,
        RESET_CACHE_FRAME_DELAY,
        RESET_CACHE_FRAME_DELAY_AFTER_N_FRAMES,
        poseThresholdValue,
        faceSharpnessThresholdValue,
        faceBrightnessThresholdValue,
      };
    }
  });

  useEffect(() => {
    localStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify(detectionSettings));
  }, [detectionSettings]);



  const updateDetectionSetting = (key, value) => {
    if (key === 'RESET_CACHE_FRAME_DELAY') {
      setDetectionSettings((prevSettings) => ({
        ...prevSettings,
        [key]: value,
        RESET_CACHE_FRAME_DELAY_AFTER_N_FRAMES: (1000 / prevSettings.DETECTION_DELAY) * value,
      }));
    } else if (key === 'poseThresholdValue') {
      setDetectionSettings((prevSettings) => ({
        ...prevSettings,
        [key]: value,
      }));
    } else if (key === 'faceSharpnessThresholdValue') {
      setDetectionSettings((prevSettings) => ({
        ...prevSettings,
        [key]: value,
      }));
    } else if (key === 'faceBrightnessThresholdValue') {
      setDetectionSettings((prevSettings) => ({
        ...prevSettings,
        [key]: value,
      }));
    } else {
      setDetectionSettings((prevSettings) => ({
        ...prevSettings,
        [key]: value,
      }));
    }
  };
  

  const settingsContextValue = {
    detectionSettings,
    updateDetectionSetting,
   
  };

  return (
    <SettingsContext.Provider value={settingsContextValue}>
      {children}
    </SettingsContext.Provider>
  );
}
