import { DataGrid } from "@mui/x-data-grid";

export default function MyDataGrid(props) {
  return (
    <DataGrid
      sx={{
        ".MuiDataGrid-columnHeaders": {
          background: "#0B0B45",
          color: "white",
        },
      }}
      {...props}
    />
  );
}
