import React from "react";
import { Link } from "react-router-dom";
import { Grid, Stack, Paper, Container } from "@mui/material";
import EditUser from "../Assets/images/EditUser.svg";
import CreateStaff from "../Assets/images/CcreateStaff.svg";
import Club from "../Assets/images/Club.svg";
import UnverifiedAccounts from "../Assets/images/UnverifiedAccounts.svg";
import Admin from "../Assets/images/Admin.svg";
import Customer from "../Assets/images/Customer.svg";
import SelfRegistration from "../Assets/images/self-registration.svg";
import { getUserRole } from "../authUtils";

export default function Home() {
  const userRole = getUserRole();
  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <Grid container spacing={3} justifyContent="space-evenly">
          {(userRole === "admin" || userRole === "superadmin") && (
            <Card
              label="Customer Management"
              icon={EditUser}
              link="/update-user"
            />
          )}
          {(userRole === "admin" ||
            userRole === "superadmin" ||
            userRole === "staff") && (
            <Card label="Check-in" icon={Customer} link="/customer" />
          )}
          {(userRole === "admin" ||
            userRole === "superadmin" ||
            userRole === "staff") && (
            <Card label="Check-in History" icon={Admin} link="/history" />
          )}

          {(userRole === "admin" ||
            userRole === "superadmin" ||
            userRole === "staff") && (
            <Card
              label="Self registration"
              icon={SelfRegistration}
              link="/self-registration"
            />
          )}

          {(userRole === "admin" || userRole === "superadmin") && (
            <Card
              label="Unverified Members"
              icon={UnverifiedAccounts}
              link="/unverified-members"
            />
          )}

          {(userRole === "admin" || userRole === "superadmin") && (
            <Card label="Staff Management" icon={CreateStaff} link="/staff" />
          )}
          {(userRole === "admin" || userRole === "superadmin") && (
            <Card label="Club Management" icon={Club} link="/club" />
          )}
        </Grid>
      </Container>
    </>
  );
}

function Card({ icon, label, link = "" }) {
  return (
    <Grid item xs={4}>
      <Link to={link}>
        <Paper
          style={{
            width: "100%",
            padding: "24px 0",
            color: "white",
            background: "#0B0B45",
          }}
          sx={{
            "&:hover": {
              boxShadow: "0px 0px 30px 0px #0B0B4599",
            },
          }}
        >
          <Stack alignItems="center">
            <img width={60} height={60} src={icon} alt="" />
            <p style={{ margin: "20px 0 0", textWrap: "nowrap" }}>{label}</p>
          </Stack>
        </Paper>
      </Link>
    </Grid>
  );
}
