import http from "./http";
import GlobalLoading from "./Components/loading";

export const USER_INFO = "USER_INFO";
export const ROLE = "ROLE";
export const AUTH_TOKEN = "AUTH_TOKEN";

export const CLUB_ID = "_CLUB_ID_";

export const LOGIN_URL =
  window.location.host === "app.guest.vision"
    ? "https://auth.test.guest.vision/login?client_id=7f9j4inf9ta03o9h01fbs4vckh&response_type=token&scope=aws.cognito.signin.user.admin+openid&redirect_uri=https://app.guest.vision"
    : "https://auth.test.guest.vision/login?client_id=7f9j4inf9ta03o9h01fbs4vckh&response_type=token&scope=aws.cognito.signin.user.admin+openid&redirect_uri=https://test.guest.vision";

export const asyncGetUserInfo = async () => {
  try {
    // 如果没有token，不校验
    const tk = getToken();
    if (!tk) {
      return;
    }
    const res = await http.get("/auth/users/me");
    console.log("userInfo res ==>>", res);
    setUserInfo(res.username);
    setUserRole(res.groups[0]);
    setClubId(res.club_id);
    // 不通过 就是token失效
  } catch (e) {
    console.log("userInfo e==>>", e);
    // 不通过 就是token失效
    logoutFn();
  }
};

export const logoutFn = () => {
  removeUserInfo();
  removeToken();
  removeUserRole();
  removeClubId();
  window.location.href = "/";
};

export const getClubId = () => {
  try {
    return localStorage.getItem(CLUB_ID);
  } catch (e) {}
};

export const setClubId = (data) => {
  try {
    localStorage.setItem(CLUB_ID, data);
  } catch (e) {}
};

export const removeClubId = () => {
  try {
    localStorage.removeItem(CLUB_ID);
  } catch (e) {}
};

export const getUserInfo = () => {
  try {
    return localStorage.getItem(USER_INFO);
  } catch (e) {}
};

export const setUserInfo = (userInfo) => {
  try {
    localStorage.setItem(USER_INFO, userInfo);
  } catch (e) {}
};

export const removeUserInfo = () => {
  try {
    localStorage.removeItem(USER_INFO);
  } catch (e) {}
};

export const getToken = () => {
  try {
    return localStorage.getItem(AUTH_TOKEN);
  } catch (e) {}
};

export const setToken = (tk) => {
  try {
    localStorage.setItem(AUTH_TOKEN, tk);
  } catch (e) {}
};

export const removeToken = () => {
  try {
    localStorage.removeItem(AUTH_TOKEN);
  } catch (e) {}
};

export const getUserRole = () => {
  try {
    return localStorage.getItem(ROLE);
  } catch (e) {}
};

export const setUserRole = (role) => {
  try {
    localStorage.setItem(ROLE, role);
  } catch (e) {}
};

export const removeUserRole = () => {
  try {
    localStorage.removeItem(ROLE);
  } catch (e) {}
};
