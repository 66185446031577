import React from 'react'
import {
  Stack,
} from '@mui/material'
import Btn from '../../Components/Btn'


const TakePicture = ({ pictureCaptured }) => {
  const [capturedImg, setCapturedImg] = React.useState(null)
  const stream = React.useRef()
  const videoRef = React.useRef()
  const canvasRef = React.useRef()
  const WIDTH = 400
  const HEIGHT = 300

  React.useEffect(() => {
    (async () => {
      const cameras = await navigator.mediaDevices.enumerateDevices()
      if (cameras.length === 0)
        return alert('No cameras detected')

      const st = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId: cameras[0].deviceId } });
      videoRef.current.srcObject = st
      videoRef.current.play()
      stream.current = st
    })()

    return () => {
      stream.current?.getTracks().forEach(t => t.stop())
    }
  }, [])

  const takePhoto = () => {
    const context = canvasRef.current.getContext('2d')
    context.drawImage(videoRef.current, 0, 0, WIDTH, HEIGHT)
    canvasRef.current.toBlob(file => {
      setCapturedImg(file)
    })
  }

  const handleSubmitPicture = () => {
    const img = new File([capturedImg], Math.random().toString(36) + '.jpg')
    pictureCaptured(img)
  }

  return (
    <Stack spacing={1} sx={{ m: 2 }}>
      <video
        style={{ display: capturedImg ? 'none' : 'block' }}
        ref={videoRef}
        width={WIDTH}
        height={HEIGHT}
      />
      {!capturedImg &&
        <Btn variant="contained" onClick={takePhoto}>Take picture</Btn>
      }

      {capturedImg && <>
        <img
          src={URL.createObjectURL(capturedImg)}
          width={WIDTH}
          height={HEIGHT}
        />
        <Stack direction="row" spacing={1}>
          <Btn variant="contained" onClick={handleSubmitPicture} fullWidth>Keep picture</Btn>
          <Btn variant="contained" onClick={() => setCapturedImg(null)} fullWidth>Take another</Btn>
        </Stack>
      </>}
      <canvas
        width={WIDTH}
        height={HEIGHT}
        ref={canvasRef}
        style={{ display: 'none' }}
      />
    </Stack>
  )
}


export default TakePicture
