let API_URL = 'http://adeb7543807f9438fa3ead6d4b5723e3-1389983989.us-west-2.elb.amazonaws.com'
let WS_API_URL = 'wss://guest.vision/api'

// these models are hosted by nginx under /var/www/guest.vision/models/
let MODELS_URL = '/'

console.log('process.env?.NODE_ENV==>>', process.env?.NODE_ENV)
if (process.env?.NODE_ENV === 'development') {
  API_URL = 'http://adeb7543807f9438fa3ead6d4b5723e3-1389983989.us-west-2.elb.amazonaws.com'
  WS_API_URL = 'wss://guest.vision/api'
  MODELS_URL = '/'
}

export const ERROR_CLR = '#e57575'
export const SUCCESS_CLR = '#81e781'


export {
  API_URL,
  WS_API_URL,
  MODELS_URL,
}
